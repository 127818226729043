@font-face {
    font-family: "Poppins-Regular";
    font-weight: 400;
    src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
         url("../fonts/Poppins-Regular.woff") format("woff"),
         url("../fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    src: url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
         url("../fonts/Poppins-SemiBold.woff") format("woff"),
         url("../fonts/Poppins-SemiBold.ttf") format("truetype");
}