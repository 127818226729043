.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@for $i from 1 through 5 {
  .mt-#{$i} { margin-top: 8px * $i; }
  .mb-#{$i} { margin-bottom: 8px * $i; }
}

.index-page {
  button.debug {
    bottom: 100px;
  }
}

.snapchat-transition-page {
  position: relative;
  overflow: hidden;
  height: 100%;
  padding-bottom: 9.7vh;
  .container {
    max-width: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .snapchat__text {
    text-align: center;
  }
}