.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 50;
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1.67;
        text-align: center;
        position: absolute;
        top: calc(50% + 120px);
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
    }
    &__blur {
        filter: blur(25px);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &__dots {
        width: 0;
        height: 0;
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        margin: auto;
        filter: url("/assets/img/sprite.svg#goo");
    }
    &__dot {
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;
        &:before {
            content: '';
            width: $dot-size;
            height: $dot-size;
            border-radius: 50px;
            background: $color-yellow;
            position: absolute;
            left: 50%;
            transform: translateY(0) rotate(0deg);
            margin-left: -($dot-size/2);
            margin-top: -($dot-size/2);
        }
        
        @keyframes dot-move {
            0% {
                transform: translateY(0);
            }
            18%, 22% {
                transform: translateY(-($circle-size));
            }
            40%, 100% {
                transform: translateY(0);
            }
        }
    
        @keyframes dot-colors {
            @for $i from 0 to 4 {
                #{$i*25%} {
                    background-color: nth($colors, ($i+3)%4+1);
                }
            }
            100% {
                background-color: nth($colors, 4);
            }
        }
    
        &:nth-child(5):before {
            z-index: 100;
            width: $dot-size * 1.2;
            height: $dot-size * 1.2;
            margin-left: -($dot-size * .6);
            margin-top: -($dot-size * .6);
            animation: dot-colors $animation-time ease infinite;
        }
    
        @for $i from 0 to 4 {
            @keyframes dot-rotate-#{$i + 1} {
                0% {
                    transform: rotate(#{($i+1)*270deg - 375deg});
                }
                100% {
                    transform: rotate(#{($i+1)*270deg + 0deg});
                }
            }
    
            &:nth-child(#{$i + 1}) {
                animation: dot-rotate-#{$i + 1} $animation-time #{$i * $animation-time / 4} linear infinite;
    
                &:before {
                    background-color: nth($colors, $i+1);
                    animation: dot-move $animation-time #{$i * $animation-time/4} ease infinite;
                }
            }
        }
    }
    &_small {
        .loader__dot {
            &:before {
                content: '';
                width: $dot-size-small;
                height: $dot-size-small;
                margin-left: -($dot-size-small/2);
                margin-top: -($dot-size-small/2);
            }
            
            @keyframes dot-move {
                0% {
                    transform: translateY(0);
                }
                18%, 22% {
                    transform: translateY(-($circle-size-small));
                }
                40%, 100% {
                    transform: translateY(0);
                }
            }
        
            &:nth-child(5):before {
                width: $dot-size-small * 1.2;
                height: $dot-size-small * 1.2;
                margin-left: -($dot-size-small * .6);
                margin-top: -($dot-size-small * .6);
            }
        }
        .loader__text {
            font-size: 16px;
            top: calc(50% + 80px);
        }
    }
    &__processing {
        position: absolute;
    }
    &__previews {
        position: relative;
        min-height: 200px;
        width: 100%;
    }
}
