
.bubble {
    border-radius: 50%;
    position: absolute;
    opacity: .3;
    filter: blur(100px);
    z-index: -5;
}
.bubble-ex-large {
    width: 240px;
    height: 240px;
}
.bubble-large {
    width: 200px;
    height: 200px;
}
.bubble-medium {
    width: 160px;
    height: 160px;
}
.bubble-small {
    width: 120px;
    height: 120px;
}