@import "./fonts.scss";
@import "./variables.scss";
@import "./btn.scss";
@import "./bubbles.scss";
@import "./loader.scss";

* {
    box-sizing: border-box;
}

html, body, .root, .root-router-host {
    height: 100%;
}

.root > div {
    display: flex;
    flex-direction: column;
    height: 100%;
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 16px;
    color: #000;
    background-color: $color-white;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

a {
    text-decoration: none;
}

p {
    line-height: normal;
}

input {
    padding: 0;
    margin: 0;
}

.main-section {
    padding-top: 56px;
}

.container {
    max-width: 1170px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: auto;
    margin-right: auto;
}

.title-h2 {
    font-size: 48px;
    line-height: 1.5;
}
.title-h3 {
    font-size: 24px;
    line-height: 1.67;
}
