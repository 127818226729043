.logo {
    font-size: 24px;
    line-height: 1;
    color: $color-black;
    display: inline-flex;
    position: relative;
    z-index: 4;
    margin-bottom: 48px;

    span {
        color: #dd4044;
    }
}

a.logo {
    cursor: pointer;
    &:hover {
        opacity: 1;
        text-shadow: 0 0 30px $color-blue-dark;
    }
}

/*main page*/
.main-page {
    position: relative;
    overflow: hidden;
    height: 100%;
    padding-bottom: 9.7vh;
    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .logo {
        align-self: flex-start;
        margin-bottom: 14.5vh;
    }
    &__content {
        flex-grow: 1;
        max-width: 640px;
    }
    &__title {
        margin-bottom: 24px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1.67;
        margin-bottom: 64px;
    }
    &__bg {
        max-width: 1050px;
        width: 100%;
        position: absolute;
        top: 72px;
        left: 60%;
        transform: translateX(-50%);
        z-index: -5;
    }
    &__bubble1 {
        top: 140px;
        left: -70px;
        background-color: $color-pink;
    }
    &__bubble2 {
        top: 86px;
        left: 22%;
        background-color: $color-blue-dark;
    }
    &__bubble3 {
        bottom: 24px;
        left: 45%;
        background-color: $color-green;
    }
    &__bubble4 {
        top: 168px;
        left: 62%;
        background-color: $color-pink;
    }
    &__bubble5 {
        bottom: 176px;
        left: 86%;
        background-color: $color-blue-dark;
    }
}

/*result-page*/
.result-page {
    .logo {
        margin-bottom: 48px;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        &-container {
            margin-bottom: 56px;
            .title-h3 {
                margin-bottom: 16px;
            }
            p {
                font-family: "Poppins", sans-serif;
                line-height: 1.75;
                color: #666;
                margin-bottom: 22px;
            }
        }
        &.guest {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);
            .result-page__column_1-1 {
                grid-row: 1/2;
                grid-column: 1/2;
            }
            .result-page__column_1-2 {
                grid-row: 2/3;
                grid-column: 1/2;
            }
            .result-page__column_2 {
                grid-row: 1/3;
                grid-column: 2/3;
                align-items: flex-end;
                max-width: 100%;
                margin-left: 0;
                & > div {
                    width: 100%;
                    max-width: 480px;
                }
            }
        }
    }
    &__column {
        &_1 {
            max-width: 600px;
            width: 100%;
            padding-right: 16px;
            margin-top: -14px;
            .result-page__action-btn {
                display: inline-flex;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #3758d6;
                position: relative;
                padding: 0;
                margin: 0;
                margin-right: 40px;
                svg {
                    fill: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin: 0;
                }
                &::before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 40px;
                    background-color: #cdcdcd;
                    position: absolute;
                    right: -20px;
                }
            }
        }
        &_2 {
            flex-shrink: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            max-width: 480px;
        }
    }
    &__title {
        margin-bottom: 24px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1.67;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    &__collage {
        max-width: 480px;
        width: 100%;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 16px;
        &-title {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 8px;
        }
        &-subtitle {
            font-family: "Poppins", sans-serif;
            line-height: 1;
            color: #666666;
            margin-bottom: 24px;
        }
    }
    &__action-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &_guest {
           justify-content: flex-end;
        }
    }
    &__action-btn {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
        padding: 5px 0;
    }
    &__action-btn-icon {
        width: 16px;
        margin-right: 16px;
    }
}

/*share block*/
.share-container {
    &__title {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1;
        text-align: left;
        color: $color-grey-dark;
        margin-bottom: 16px;
        &-mob {
            font-family: "Poppins-Regular", sans-serif;
            font-size: 24px;
            line-height: 1;
            text-align: left;
            color: $color-grey-dark;
            margin-bottom: 16px;
            display: none;
        }
    }
    &__btn {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 16px;
    }
    &__btn-icon {
        width: 100%;
        height: 100%;
    }
    &_small {
        .share-container {
            &__title {
                font-size: 16px;
                text-align: right;
                margin-bottom: 8px;
            }
            &__btn {
                width: 56px;
                height: 56px;
                margin-left: 8px;
                margin-right: 0;
                &-download-small {
                    position: relative;
                    margin-right: 32px;
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        right: -20px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 56px;
                        background-color: #cdcdcd;
                    }
                }
            }
        }
    }
    &__btn-download {
        font-size: 24px;
        margin-bottom: 40px;
        svg {
            width: 24px;
            margin-right: 8px;
        }
    }
}

/*create page*/
.create-page {
    padding-bottom: 56px
}
.portraits {
    &__container {
        display: flex;
        justify-content: space-between;
    }
    &_preview {
        .logo {
            margin-bottom: 130px;
        }
        .portraits__title-container {
            flex-direction: column;
            margin-bottom: 0;
        }
        .btn {
            margin-top: 16px;
        }
    }
    &__title-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 56px;
    }
    &__title {
        max-width: 600px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        width: 262px;
        height: 262px;
        border-radius: 12px;
        border: solid 1px $color-grey;
        overflow: hidden;
        margin: 0 24px 24px 0;
        &:nth-child(4n) {
            margin-right: 0;
        }
        &_active {
            box-shadow: 0 4px 30px 0 $box-shadow-color;
            border: solid 8px $color-blue-dark;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }
    &__preview-image {
        max-width: 480px;
        max-height: 480px;
        width: 100%;
        border-radius: 12px;
        object-fit: contain;
    }
}
.collage-type {
    &__title {
        margin-bottom: 24px;
    }
    &__list {
        display: flex;
        margin-bottom: 40px;
    }
    &__item {
        width: 262px;
        height: 262px;
        border-radius: 12px;
        background-color: $color-green;
        background-size: cover;
        cursor: pointer;
        margin-right: 24px;
        &_check {
            box-shadow: 0 4px 30px 0 $box-shadow-color;
            border: solid 8px $color-blue-dark;
        }
        &--vector {
            background-image: url("/assets/img/collages-styles/vector.jpg");
        }
        &--cartoon {
            background-image: url("/assets/img/collages-styles/cartoon.jpg");
        }
    }
}

/*form*/
.form {
    margin-bottom: 56px;
    &__column {
        display: flex;
        flex-direction: column;
        &_first {
            margin-bottom: 28px;
        }
        &_second {
            flex-shrink: 0;
            width: 100%;
        }
        &-container {
            display: flex;
            justify-content: space-between; 
        }
        &-info {
            width: 45%;
            padding-top: 28px;
        }
    }
    &__title-block {
        display: flex;
        align-items: flex-start;
        position: relative;
    }
    &__title {
        max-width: 600px;
        margin-bottom: 24px;
        &-highlight {
            color: $color-blue-dark;
        }
    }
    &__tooltip-container {
        position: relative;
        top: 10px;
    }
    &__tooltip-btn {
        font-size: 24px;
        color: $color-white;
        width: 40px;
        height: 40px;
        border-radius: 6px;
        background-color: $color-blue-dark;
        position: relative;
        top: -12px;
        left: 16px;
    }
    &__subtitle {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1.5;
        text-align: left;
    }
    &__title-input {
        font-family: "Poppins-SemiBold", sans-serif;
        line-height: 1.5;
        text-align: left;
        color: $color-black;
        margin-bottom: 8px;
    }
    &__type {
        &-container {
            margin-bottom: 40px;
        }
        &-title {
            margin-bottom: 16px;
        }
        &-block {
            display: flex;
            flex-direction: column;
        }
    }
    &__radio {
        position: absolute;
        z-index: -1;
        opacity: 0;
        &:checked + {
            .form__label-name {
                &:after {
                    content: '';
                }
                &:before {
                    background-color: $color-black;
                }
            }
        }
    }
    &__label {
        font-family: "Poppins-Regular", sans-serif;
        cursor: pointer;
        &:first-child {
            margin-bottom: 8px;
        }
        &-name {
            font-size: 24px;
            line-height: 1;
            color: $color-black;
            position: relative;
            display: flex;
            align-items: center;
            &:before {
                content: '';
                display: block;
                width: 40px;
                height: 40px;
                background-color: $color-grey-dark;
                flex-shrink: 0;
                border-radius: 50%;
                margin-right: 8px;
            }
            &:after {
                content: none;
                display: block;
                width: 7px;
                height: 16px;
                border: solid $color-white;
                border-width: 0 2px 2px 0;
                position: absolute;
                left: 16px;
                top: 48%;
                transform: translateY(-50%) rotate(45deg);
            }
        }
        &-subname {
            line-height: 1.5;
            color: $color-grey-dark;
            margin-left: 48px;
        }
    }
    &__input {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 24px;
        border-radius: 12px;
        border: solid 2px $color-grey-dark;
        height: 80px;
        width: 100%;
        line-height: 3.5;
        letter-spacing: 0.48px;
        padding: 0 24px;
        &-container {
            position: relative;
        }
    }
    &__email {
        width: 100%;
        &-container {
            position: relative;
            margin-bottom: 40px;
        }
        &-error {
            .form__email {
                border-color: $color-error;
                &-title {
                    color: $color-error;
                }
            }
            &-message {
                font-family: "Poppins-Regular", sans-serif;
                line-height: 1;
                color: $color-error;
                position: absolute;
                top: 88px;
                left: 0;
            }
        }
    }
    &__number {
        text-align: center;
        width: 80px;
        padding: 0 6px;
        margin-right: 8px;
        &-block {
            display: flex;
        }
        &-btn-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    &__container {
        display: flex;
        align-items: flex-start;
        margin-bottom: 40px;
    }
    &__preview {
        margin-left: 40px;
        &-btn {
            color: $color-blue-dark;
            display: flex;
            align-items: center;
            background-color: transparent;
            margin-bottom: 12px;
            &_show {
                .form__preview-btn-icon {
                    transform: rotate(90deg);
                }
            }
            &-icon {
                width: 16px;
                height: 16px;
                transition: .6s;
                margin-right: 12px;
            }
            &-value {
                font-family: "Poppins-SemiBold", sans-serif;
                line-height: 1.5;
                letter-spacing: 0.64px;
                text-transform: uppercase;
            }
        }
        &-image {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: bottom;
            &-container {
                width: 325px;
                height: 0;
                border-radius: 12px;
                overflow: hidden;
                transition: .6s;
                &_show {
                    height: auto;
                }
            }
        }
    }
    &__avatar {
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 45%;
            background-color: #f6f6f6;
            border-radius: 20px;
            padding-top: 28px;
            padding-bottom: 40px;
            .btn {
                max-width: 264px;
                width: 100%;
            }
        }
        &-title {
            width: 262px;
        }
        &-block {
            width: 262px;
            height: 262px;
            border-radius: 12px;
            overflow: hidden;
            flex-shrink: 0;
            margin-bottom: 24px;
        }
        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }
    }
}

/*tooltip*/
.tooltip {
    border-radius: 12px;
    box-shadow: 0 4px 30px 0 $box-shadow-color;
    background-color: $color-white;
    width: 331px;
    padding: 16px;
    position: absolute;
    right: -354px;
    top: 0;
    z-index: 100;
    &__title {
        line-height: 1.5;
        margin-bottom: 8px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        line-height: 1.5;
    }
    &__btn-close {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 16px;
        height: 16px;
        background-color: transparent;
        &:hover {
            opacity: .6;
        }
        &:before, 
        &:after {
            content: ' ';
            position: absolute;
            top: -2px;
            left: 7px;    
            height: 20px;
            width: 2px;
            background-color: $color-grey-dark;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    &__triangle {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        left: -15px;
        overflow: hidden;
        transform: translateY(50%) rotate(60deg) skewY(30deg) scaleX(0.866);
        pointer-events: none;
        border-radius: 20%;
        &::before, 
        &::after {
            content: '';
            position: absolute;
            background-color: $color-white;
            pointer-events: auto;
            width: 30px;
            height: 30px;
        }
        &::before {
            transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
            border-radius: 20% 20% 20% 55%;
        }
        &::after {
            transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
            border-radius: 20% 20% 55% 20%;
        }
    }
}

/*modal*/
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    &__container {
        max-width: 800px;
        width: 90%;
        border-radius: 16px;
        box-shadow: 0 8px 60px 0 $box-shadow-color;
        background-color: $color-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 10;
        padding: 40px;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__collage {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 16px;

        &-item {
            position: relative;
            width: 256px;
            margin: 0 5px;
            &:after {
                content: '';
                display: block;
                width: 100%;
                padding-top: 100%;
            }
            .imageview-host {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__image {
        margin-bottom: 40px;
        &_desktop {
            max-width: 594px;
        }
        &_mob {
            display: none;
            width: calc(100% + 32px);
        }
    }
    &__title {
        text-align: center;
        margin-bottom: 8px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        text-align: center;
        line-height: 1.67;
        margin-bottom: 40px;
    }
    &__bubble1 {
        left: 42px;
        top: 48px;
        background-color: $color-pink;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
    }
    @supports (backdrop-filter: none) {
        &:after {
            background-color: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(10px);
        }
    }
    
    @supports not (backdrop-filter: none) {
        &:after {
            background-image: url("/assets/img/bg/bg-white.png");
            opacity: .6;
            filter: blur(10px);
        }
    }
}

/*manage-page*/
.manage-page {
    padding-bottom: 56px;
    .logo {
        margin-bottom: 56px;
    }
    &__btns-container {
        display: flex;
        justify-content: space-between;
    }
    &__btn-download {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
    }
    &__preview {
        display: flex;
        justify-content: space-between;
        margin-bottom: 56px;
    }
    &__title {
        margin-bottom: 8px;
        &-block {
            max-width: 640px;
        }
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1.67;
    }
    &__btns-block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        &-create {
            margin-bottom: 16px;
            .btn {
                margin-left: 16px;
            }
        }
    }
    &__content {
        display: flex;
    }
    &__collage-block {
        max-width: 480px;
        width: 100%;
        flex-shrink: 0;
        margin-right: 20px;
        &-processing {
            width: 100%;
            margin-bottom: 24px;
        }
        .imageview-host {
            border-radius: 12px;
            overflow: hidden;
        }
    }
    &__edit-block {
        max-height: 480px;
        flex-grow: 1;
        flex-shrink: 0;
        position: relative;
        width: calc(100% + 20px);
        max-width: 644px;
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            height: 64px;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 255, 255, .8) 60%);
        }
    }
    &__edit-container {
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar-track {
            border-radius: 8px;
            background-color: transparent;
        }
    
        &::-webkit-scrollbar {
            width: 4px;
            background-color: transparent;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: $color-grey;
        }
    }
    &__pending-block {
        padding: 0 20px;
        margin-bottom: 20px;
    }
}

.pending-block {
    margin-bottom: 24px;
    &__title {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 16px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 138px;
        height: 138px;
        border-radius: 12px;
        box-shadow: 0 4px 20px 0 $box-shadow-color;
        border: solid 1px $color-grey;
        overflow: hidden;
        position: relative;
        overflow: hidden;
        margin-bottom: 16px;
        margin-right: 16px;
        &:nth-child(4n) {
            margin-right: 0;
        }
        &:after {
            content: "";
            opacity: 0;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: .6s;
            background-color: rgba(0, 0, 0, .2);
        }
        &:hover {
            &:after {
                opacity: 1;
            }
            .pending-block__image {
                filter: blur(5px);
            }
            .pending-block__btn {
                opacity: 1;
            }
        }
    }
    &__btn {
        opacity: 0;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 0.75);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .6s;
        position: relative;
        z-index: 5;
        margin: 0 9px;
        &-icon {
            height: 16px;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        transition: .6s;
        position: absolute;
        top: 0;
        left: 0;
    }
}

/*copied-block*/
.copied-block {
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 4px 30px 0 $box-shadow-color;
    background-color: $color-black;
    animation: hide 2s ease-in 1s;
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    &__icon {
        width: 56px;
        height: 56px;
        margin-right: 24px;
    }
    &__content {
        font-size: 16px;
        color: $color-white;
    }
    &__title {
        line-height: 1.5;
        margin-bottom: 8px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        line-height: 1.5;
    }
    &__btn-close {
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        background-color: transparent;
        padding: 6px;
    }
    &__icon-close {
        width: 12px;
        height: 12px;
    }
}

@keyframes hide {
    100% {
        opacity: 0;
    }
}

.caption-form {
    margin-bottom: 24px;
    &__title {
        font-size: 14px;
        line-height: 1.43;
        margin-bottom: 8px;
    }
    &__container {
        height: 56px;
        display: flex;
        align-items: center;
    }
    &__input {
        border-radius: 12px;
        border: solid 2px #ebebeb;
        height: 100%;
        flex-grow: 1;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 8px;
    }
    &__button {
        flex-shrink: 0;
    }
}

.owner-mob {
    display: none;
}

@media all and (max-width: 1170px) {
    .manage-page {
        &__collage-block {
            // max-width: 400px;
        }
        &__edit-block {
            flex-shrink: 1;
        }
    }
    .pending-block__item {
        width: 30%;
        height: 100%;
        margin-right: 3%;
        &:nth-child(4n) {
            margin-right: 3%;
        }
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }
}

@media all and (max-width: 1070px) {
    .portraits__item:nth-child(4n) {
        margin-right: 24px;
    }
    .result-page {
        &__action {
            &-btns {
                flex-direction: column;
                & > div {
                    width: 100%;
                }
                &.result-page_collage-owner {
                    flex-direction: row;
                    .result-page__action-btn {
                        border: none;
                        height: auto;
                        margin: 0;
                    }
                }
            }
            &-btn {
                border: 2px solid $color-blue-dark;
                height: 56px;
                margin-bottom: 16px;
                width: 100%;
                max-width: 100%;
            }
        }
        &__moderate-btn {
            max-width: 100%;
            width: 100%;
        }
        &__column_2 {
            width: 100%;
            margin-left: 30px;
        }
        .logo {
            margin-bottom: 40px;
        }
        &-btn_upload-collage-owner {
            display: block;
        }
    }
    .manage-page {
        &__title-block {
            max-width: 500px;
        }
        &__collage-block {
            max-width: 400px;
        }
    }
    .portraits_preview {
        .logo {
            margin-bottom: 40px;
        }
    }
}

@media all and (max-width: 1030px) and (orientation: portrait) {
    .main-page {
        &__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}

@media all and (max-width: 991px) {
    .main-section {
        padding-top: 24px;
        padding-bottom: 6.7vh;
    }
    .title-h2 {
        font-size: 40px;
        line-height: 1.4;
    }
    .main-page {
        &__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        &__title {
            margin-bottom: 8vh;
        }
        &__bg {
            top: 0;
            left: 0;
            right: 0;
            transform: none;
        }
    }
    .form {
        flex-direction: column;
        &__title {
            max-width: 100%;
        }
        &__column_first {
            margin-right: 0;
        }
    }
}

@media all and (max-width: 950px) {
    .result-page {
        &__content {
            flex-direction: column;
            padding-bottom: 0;
            &.guest {
                grid-template-columns: 1fr;
                .result-page__column_1 {
                    margin-bottom: 40px;
                }
                .result-page__column_1-2 {
                    grid-row: 3/4;
                }
                .result-page__column_2 {
                    grid-row: 2/3;
                    grid-column: 1/2;
                    align-items: flex-start;
                }
                .result-page__column_2 > div {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
            &.owner {
                .result-page__column_1 {
                    margin-bottom: 16px;
                }
                .result-page__column_2 > div {
                    display: flex;
                    flex-direction: column;
                    .result-page__collage-title-container {
                        order: 2;
                    }
                    .result-page__action-btns {
                        order: 3;
                    }
                }
            }
        }
        &__column {
            &_1 {
                max-width: 100%;
                padding-right: 0;
                margin-top: 0;
                margin-bottom: 40px;
                .share-container {
                    &__btn {
                        margin: 0;
                        margin-left: 8px;
                    }
                }
                .result-page__action-btn {
                    margin-right: 24px;
                    &::before {
                        right: -16px;
                    }
                }
            }
            &_2 {
                margin: 0;
            }
        }
    }
    .owner-desktop {
        display: none;
    }
    .owner-mob {
        display: block;
        &.caption-form{
            margin-top: 40px;
        }
    }
    .portraits__title {
        max-width: 100%;
    }
    .portraits__container {
        flex-direction: column;
        .btn {
            display: block;
        }
    }
    .portraits_preview .portraits__title-container {
        margin-bottom: 40px;
    }    
}

@media all and (max-width: 910px) {
    .portraits__item {
        width: 175px;
        height: 175px;
        margin: 0 16px 16px 0;
    }
    .manage-page {
        .logo {
            margin-bottom: 40px;
        }
        &__edit-block {
            max-width: initial;
            width: calc(100% + 40px);
            margin-left: -20px;
            &:after {
                content: none;
            }
        }
        &__content {
            flex-direction: column;
        }
        &__collage-block {
            max-width: 480px;
            margin: 0;
            margin-bottom: 40px;
        }
        &__preview {
            flex-direction: column;
            margin-bottom: 40px;
        }
        &__title-block {
            max-width: 100%;
            margin-bottom: 16px;
        }
        &__btns-block {
            align-items: flex-start;
            &-create {
                margin-bottom: 24px;
                .btn {
                    margin-left: 0;
                    margin-bottom: 16px;
                    display: block;
                }
            }
        }
        .share-container_small {
            .share-container {
                &__title {
                    text-align: left;
                }
                &__btn {
                    margin-left: 0;
                    margin-right: 8px;
                    &-download-small {
                        margin-right: 32px;
                        &::before {
                            right: -16px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: 870px) {
    .portraits__title-container {
        flex-direction: column;
        margin-bottom: 24px;
    }
    .portraits__title {
        margin-bottom: 16px;
    }
}

@media all and (max-width: 768px) {
    .caption-form__container {
        height: 64px;
    }
    .caption-form__input {
        border-radius: 8px;
    }
    .btn {
        max-width: 366px;
        height: 64px;
        border-radius: 8px;
        &_height-big {
            height: 80px;
        }

    }
    .form {
        &__container {
            flex-direction: column;
        }
        &__title-block {
            position: static;
        }
        &__tooltip-btn {
            position: absolute;
            right: 24px;
            top: 24px;
            left: auto;
        }
        &__title {
            margin-bottom: 16px;
            &-input {
                font-size: 14px;
            }
        }
        &__subtitle {
            font-size: 16px;
        }
        &__column{
            &_first {
                margin-bottom: 32px;
            }
            &_second {
                padding: 0;
                max-width: 100%;
            }
            &-container {
                flex-direction: column-reverse;
            }
            &-info {
                width: 100%;
            }
        }
        &__input {
            height: 64px;
        }
        &__email-container {
            margin-bottom: 32px;
        }
        &__type-container {
            margin-bottom: 32px;
        }
        &__label {
            &-name {
                font-size: 16px;
            }
            &-subname {
                font-size: 14px;
                position: relative;
                top: -7px;
            }
            &:first-child {
                margin-bottom: 8px;
            }
        }
        &__number-block {
            position: relative;
        }
        &__number {
            width: 64px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &-btn-block {
                flex-direction: row;
                width: 366px;
            }
        }
        .btn_ex-small {
            font-size: 24px;
            letter-spacing: 0.64px;
            width: 143px;
            height: 64px;
        }
        &__preview {
            margin: 0;
            margin-top: 24px;
        }
        &__avatar-container {
            align-items: stretch;
            width: 100%;
            background-color: transparent;
            padding: 0;
        }
    }
    .logo {
        margin-bottom: 40px;
    }
    
    .title-h2 {
        font-size: 36px;
        line-height: 1.33;
    }
    .result-page {
        &__action-btn,
        &__moderate-btn {
            max-width: 100%;
        }
    }
}

@media all and (max-width: 600px) {
    .modal__collage {
        width: calc(100% + 60px);
        &-item {
            width: 48%;
        }
    }
    .loader_small .loader__text {
        font-size: 14px;
        width: 100%;
    }
}

@media all and (max-width: 450px) {
    .caption-form {
        &__container {
            flex-direction: column;
            height: auto;
        }
        &__input {
            width: 100%;
            height: 64px;
            margin: 0;
            margin-bottom: 16px;
        }
        .caption-form__button {
            font-size: 16px;
            height: 48px;
        }
    }
    .main-page__bg {
        top: 52px;
    }
    .btn {
        max-width: 100%;
    }
    .title-h2 {
        font-size: 24px;
    }
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .main-page {
        padding-bottom: 20px;
        &__title {
            margin-bottom: 16px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 56px;
        }
        &__bubble1 {
            width: 64px;
            height: 64px;
            top: 60px;
            left: 50%;
            transform: translateX(-50%);
        }
        &__bubble2 {
            top: 35%;
            left: 58%;
            width: 80px;
            height: 80px;
        }
        &__bubble4 {
            top: 48%;
            left: 0%;
        }
        &__bubble3,
        &__bubble5 {
            display: none;
        }
        &__btn {
            line-height: 1.33;
            padding: 0 15vw;
        }
    }
    .modal {
        &__container {
            padding: 16px;
        }
        &__image {
            margin-bottom: 4.5vh;
            &_desktop {
                display: none;
            }
            &_mob {
                display: block;
            }
        }
        &__title {
            margin-bottom: 16px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 32px;
        }
        &__bubble1 {
            width: 200px;
            height: 200px;
            left: 40px;
        }
    }
    .main-section {
        padding-top: 20px;
    }
    .logo {
        margin-bottom: 24px;
    }
    .form {
        &__column {
            &_first {
                margin-bottom: 24px;
            }
        }
        &__title {
            margin-bottom: 12px;
        }
        &__tooltip-btn {
            right: 16px;
            top: 16px;
        }
        &__email-container {
            margin-bottom: 24px;
        }
        &__email-error-message {
            font-size: 12px;
            top: 82px;
        }
        &__input {
            font-size: 20px;
            height: 48px;
            padding: 0 16px;
        }
        &__type-container {
            margin-bottom: 24px;
        }
        &__title-input {
            margin-bottom: 12px;
        }
        &__preview-image-container {
            width: 280px;
            &_show {
                height: 280px;
            }
        }
        &__number {
            font-size: 20px;
            width: 48px;
            height: 48px;
            padding: 0 6px;
            &-btn-block {
                max-width: 320px;
            }
        }
        &__avatar {
            &-content {
                flex-direction: column;
                align-items: flex-start;
            }
            &-block {
                width: 100%;
                max-width: 340px;
                height: auto;
                position: relative;
                margin: 0 auto 16px;
                &::before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }
            }
            &-container {
                .btn {
                    max-width: 100%;
                    font-size: 20px;
                }
            }
            &-image {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .btn_ex-small {
            font-size: 20px;
            letter-spacing: 0.8px;
            width: 128px;
            height: 48px;
        }
    }
    .portraits__list {
        justify-content: space-between;
    }
    .portraits__item {
        margin-right: 0;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
    .portraits__title-container .btn {
        background-color: $color-white;
        position: fixed;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 360px;
        width: 90%;
    }
    .portraits__container {
        .portraits__title-container {
            .btn {
                position: static;
                transform: none;
                width: 100%;
                max-width: 100%;
                &.btn_paint {
                    background-color: $color-blue-dark;
                }
            }
        }
    }
    .result-page {
        &__title {
            margin-bottom: 16px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 16px;
            margin-top: 16px;
        }
        &__collage {
            margin-bottom: 56px;
        }
        .logo {
            margin-bottom: 24px;
        }
        &__content{
            &.guest {
                .result-page__collage {
                    transform: rotate(2deg);
                    margin-bottom: 18px;
                }
                .result-page__collage-title {
                    font-size: 14px;
                    line-height: 1.43;
                    text-align: center;
                    margin-bottom: 4px;
                }
                .result-page__collage-subtitle {
                    font-size: 14px;
                    line-height: 1.43;
                    text-align: center;
                    margin-bottom: 56px;
                }
            }
            &.owner {
                .result-page__collage-title {
                    font-size: 14px;
                    line-height: 1.43;
                    text-align: center;
                    margin-bottom: 4px;
                }
                .result-page__collage-subtitle {
                    font-size: 14px;
                    line-height: 1.43;
                    text-align: center;
                    margin-bottom: 24px;
                }
                .imageview-host {
                    margin-bottom: 8px;
                }
            }
            &-container {
                .result-page {
                    &__text {
                        line-height: 1.5;
                    }
                }
            }
        }
    }
    .result-page__column_1 {
        .share-container {
            .result-page__action-btn {
                width: 64px;
                height: 64px;
            }
        }
    }
    .share-container {
        &__title,
        &__title-mob {
            font-size: 16px;
            margin-bottom: 8px;
        }
        &__btn {
            width: 64px;
            height: 64px;
            margin-right: 8px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .manage-page {
        .logo {
            margin-bottom: 24px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 0;
        }
        .manage-page__btns-block-create {
            width: 100%;
            margin-bottom: 16px;
            .btn {
                max-width: 100%;
                width: 100%;
            }
        }
    }
    .modal__collage {
        width: calc(100% + 20px);
    }


}

@media all and (max-width: 400px) {
    .portraits__item {
        width: 134px;
        height: 134px;
    }
}

@media all and (max-width: 380px) {
    .btn {
        font-size: 20px;
        letter-spacing: .8px;        
    }
    .main-page {
        &__title {
            font-size: 28px;
            margin-bottom: 12px;
        }
        &__text {
            margin-bottom: 24px;
        }
        &__btn {
            line-height: 1;
            height: 64px;
            padding: 0;
        }
    }
    .result-page__action-btns.result-page_collage-owner {
        flex-direction: column;
        align-items: stretch;
        .share-container {
            &_small {
                .share-container__title {
                    text-align: left;
                }
                .share-container__btn {
                    margin-left: 0;
                    margin-right: 8px;
                }
            }
        }
    }
    .result-page__action-btns.result-page_collage-owner .result-page__action-btn {
        border: 2px solid $color-blue-dark;
        height: 56px;
        margin-bottom: 16px;
    }
}

@media all and (max-width: 362px) {
    .main-page {
        .logo {
            margin-bottom: 0;
        }
        &__bg {
            top: 40px;
        }
    }
    .form {
        &__number-container {
            width: 100%;
        }
        &__number-btn-block {
            max-width: 100%;
            width: 100%;
        }
        .btn_ex-small {
            width: 40%;
        }
    }
}

@media all and (max-width: 340px) {
    .btn {
        font-size: 18px;
    }
    .main-page__title {
        font-size: 24px;
    }
}

@media all and (max-height: 700px) and (max-width: 640px) {
    .main-page__bg {
        top: 0px;
    }
}

@media all and (max-height: 640px) and (max-width: 640px) {
    .main-page__bg {
        top: 24px;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
    }
    .main-page__text {
        margin-bottom: 40px;
    }
}