$color-blue-dark: #3758d6;
$color-blue-hover: #04249e;
$color-white: #fff;
$color-black: #000;
$color-grey-dark: #c7c7c7;
$color-grey: #cdcdcd;
$color-yellow: #FBD301;
$color-red: #FF3270;
$color-blue: #208BF1;
$color-green-yellow: #AFE102;
$color-pink: #ea9c9e;
$color-green: #6cd899;
$color-error: #dd4044;

$box-shadow-color:  rgba(35, 63, 169, 0.45);

$dot-color: #79C3CD;
$dot-size: 80px;
$circle-size: 160px;
$dot-size-small: 40px;
$circle-size-small: 80px;
$animation-time: 4s;

$colors: ($color-pink, $color-blue, $color-green, $color-green-yellow);
